import { Injectable, Injector } from '@angular/core';
import { StickyBanner } from '../../models';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class StickyBannerService extends ApiService {
  constructor(injector: Injector) {
    super('banner', StickyBanner, injector);
  }

  setRead(stickyBanner: StickyBanner) {
    return this.request<StickyBanner>({
      method: 'PUT',
      path: `/${stickyBanner.id}/read`,
    });
  }
}
