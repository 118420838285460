import { createSelector } from '@ngrx/store';
import { apiCallStateFeature } from './api-call-state.feature';
import { ApiCallState } from './api-call-state.reducer';
import { EApiCallStateKey, EApiRequestPartKeys } from './api-call-state.enum';
import { IApiCallState, IApiRequestPart } from './api-call-state.interface';
import { IApiFilter } from '../../interfaces';
import { arraysEqual } from '@/src/app/shared/utils/arrays-equal';

export const { selectApiCallState, selectApiCallStateState } = apiCallStateFeature;

export const selectApiCallStateByKey = (props: { apiCallStateKey: EApiCallStateKey }) =>
  createSelector(selectApiCallStateState, (selectApiCallState: ApiCallState) => {
    const apiCallState = selectApiCallState.apiCallState.find(
      apiCallState => apiCallState.key === props.apiCallStateKey
    );

    return apiCallState;
  });

export const selectApiCallStateRequestPartByKey = (props: {
  apiCallStateKey: EApiCallStateKey;
  requestPartKey: EApiRequestPartKeys;
}) =>
  createSelector(selectApiCallStateByKey(props), (apiCallState: IApiCallState) => {
    const requestPart = apiCallState.requestParts.find(
      requestPart => requestPart.key === props.requestPartKey
    );

    return requestPart;
  });

export const selectApiRequestPartDataSlice = (props: {
  apiCallStateKey: EApiCallStateKey;
  requestPartKey: EApiRequestPartKeys;
  dataSliceKey: string;
}) =>
  createSelector(selectApiCallStateRequestPartByKey(props), (apiRequestPart: IApiRequestPart) => {
    return apiRequestPart.data[props.dataSliceKey];
  });

export const selectActiveFilters = (props: { apiCallStateKey: EApiCallStateKey }) =>
  createSelector(
    selectApiCallStateRequestPartByKey({
      apiCallStateKey: props.apiCallStateKey,
      requestPartKey: EApiRequestPartKeys.FILTERS,
    }),
    (apiRequestPart: IApiRequestPart) => {
      if (!apiRequestPart.data) {
        return [];
      }
      return Object.values(apiRequestPart.data).filter((filter: IApiFilter) => {
        if (filter.value == null) {
          return false;
        }

        const defaultValues = [...(filter.defaultValues || []), filter.defaultValue].filter(v => !!v);
        if (Array.isArray(filter.value)) {
          return !(filter.defaultValues || []).some((defaultValue: any) =>
            arraysEqual(filter.value as any[], defaultValue)
          );
        } else {
          return !defaultValues.some(v => v === filter.value);
        }
      }) as IApiFilter[];
    }
  );
