import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './core/components/layout/layout.component';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { AuthGuard } from './core/guard/auth.guard';
import { SignInComponent } from './core/components/sign-in/sign-in.component';
import { RedirectLoginComponent } from './core/components/redirect-login/redirect-login.component';
import { UserSuspendedComponent } from './core/components/user-suspended/user-suspended.component';
import { SignOutComponent } from './core/components/sign-out/sign-out.component';

export const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: 'sign-in',
    component: SignInComponent,
  },
  // add new routes after the following group (test will fail otherwise)
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('@modules/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'changelog',
        loadChildren: () =>
          import('@/src/app/modules/changelog/changelog.module').then(m => m.ChangelogModule),
      },
      {
        path: 'news',
        loadChildren: () => import('@/src/app/modules/news/news.module').then(m => m.NewsModule),
      },
      {
        path: 'user',
        loadChildren: () => import('@modules/user/user.module').then(m => m.UserModule),
      },
      {
        path: 'delegations',
        loadChildren: () => import('@modules/delegations/delegations.module').then(m => m.DelegationsModule),
      },
      {
        path: 'fel/search',
        loadChildren: () => import('@modules/fel-search/fel-search.module').then(m => m.FelSearchModule),
      },
      {
        path: 'demo',
        loadChildren: () => import('@modules/demo/demo.module').then(m => m.DemoModule),
      },
    ],
  },
  { path: 'auth-redirect', children: [] },
  {
    path: 'redirect-login',
    component: RedirectLoginComponent,
  },
  {
    path: 'user-suspended',
    component: UserSuspendedComponent,
  },
  {
    path: 'sign-out',
    component: SignOutComponent,
  },
  {
    path: '**',
    pathMatch: 'full',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
