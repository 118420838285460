import { AbstractControl } from '@angular/forms';

export function validateCodiceFiscale(control: AbstractControl): { [key: string]: any } | null {
  const value = control.value;

  if (!value) {
    return null;
  }

  const cf = value.toUpperCase();

  if (!/^[A-Z0-9]{16}$/.test(cf)) {
    return { codiceFiscale: true };
  }

  return null;
}
