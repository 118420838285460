export function calculateDaysDifference(date1: Date | string, date2: Date | string) {
  const _date1 = new Date(date1);
  const _date2 = new Date(date2);
  const utc1 = Date.UTC(_date1.getFullYear(), _date1.getMonth(), _date1.getDate());
  const utc2 = Date.UTC(_date2.getFullYear(), _date2.getMonth(), _date2.getDate());

  // Calculate the difference in milliseconds
  const timeDifference = utc2 - utc1;

  // Convert the time difference from milliseconds to days
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

  // Return the day difference
  return daysDifference;
}
