import { IApiFilterOption } from '@/src/app/core/interfaces';
import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'aup-select-check-all',
  templateUrl: './select-check-all.component.html',
  styleUrls: ['./select-check-all.component.scss'],
})
export class SelectCheckAllComponent {
  @Input() model: FormControl;
  @Input() values: IApiFilterOption[] = [];
  @Input() text;

  isChecked(): boolean {
    return !!(this.model?.value && this.values?.length && this.model?.value.length === this.values?.length);
  }

  isIndeterminate(): boolean {
    return !!(
      this.model?.value &&
      this.values?.length &&
      this.model?.value.length &&
      this.model?.value.length < this.values?.length
    );
  }

  toggleSelection(change: MatCheckboxChange): void {
    if (change.checked) {
      this.model.setValue(this.values.map(value => value.value));
    } else {
      this.model.setValue([]);
    }
  }
}
