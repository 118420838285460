import { Injectable, Injector } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ApiCallStateActions } from '../api-call-state/api-call-state.actions';
import { Observable, catchError, filter, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { EApiCallStateKey, EApiRequestPartKeys } from '../api-call-state/api-call-state.enum';
import { Store } from '@ngrx/store';
import { AppState } from '..';

import { IApiRequest, IApiResponse } from '../../interfaces';
import { selectApiCallStateByKey } from '../api-call-state/api-call-state.selectors';
import { mapApiCallStateToRequestParams } from './api-call-state.mapper';
import { IApiCallState } from '../api-call-state/api-call-state.interface';

@Injectable({ providedIn: 'root' })
export abstract class SuperApiCallStateEffect<T> {
  abstract apiCallStateKey: EApiCallStateKey;
  abstract featureActions: { load: any; loaded: any; loadError: any };

  abstract callApi(apiRequest: IApiRequest, apiCallState: IApiCallState): Observable<IApiResponse<T>>;

  protected actions$: Actions;
  protected store$: Store<AppState>;

  loadData$;

  constructor(public injector: Injector) {
    this.actions$ = this.injector.get<Actions>(Actions);
    this.store$ = this.injector.get<Store<AppState>>(Store<AppState>);
    this.initEffect();
  }

  initEffect() {
    this.loadData$ = createEffect(() =>
      this.actions$.pipe(
        ofType(ApiCallStateActions.load),
        filter(action => action.apiCallStateKey && action.apiCallStateKey === this.apiCallStateKey),
        withLatestFrom(
          this.store$.select(state =>
            selectApiCallStateByKey({
              apiCallStateKey: this.apiCallStateKey,
            })(state)
          )
        ),
        tap(() => this.store$.dispatch(this.featureActions.load())),
        switchMap(([_, apiCallState]) => {
          const apiRequest = mapApiCallStateToRequestParams(apiCallState);
          return this.callApi(apiRequest, apiCallState).pipe(
            //NOTE: error catching is done here because catchError on top level stops observable
            catchError(error => of(this.featureActions.loadError({ error: error.message })))
          );
        }),
        map(response => {
          // NOTE: in case that this is action (loadError) we return action
          if (response?.type && typeof response.type === 'string') {
            return response;
          }
          // NOTE: update pagination part with server response
          if (response.pagination) {
            const { page, size, totalItems } = response.pagination;
            this.store$.dispatch(
              ApiCallStateActions.updateRequestPart({
                apiCallStateKey: this.apiCallStateKey,
                requestPartKey: EApiRequestPartKeys.PAGINATION,
                data: { page, size, totalItems },
              })
            );
          }
          return this.featureActions.loaded({ response });
        })
      )
    );
  }
}
