import { LoaderService } from '@/src/app/core/services';
import { Component } from '@angular/core';

@Component({
  selector: 'aup-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  constructor(public loaderService: LoaderService) {}
}
