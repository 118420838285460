import { EFelStatus } from '../enums';
import { SuperModel } from './super-model.model';
import * as moment from 'moment';

export class Fel extends SuperModel {
  override id: string;
  user: string;
  name: string;
  expirationDate: Date;
  delegationStatus: EFelStatus;
  delegationAcceptedRefusedDate: Date;
  vatCode: string;
  grants: IGrant[];
  countryId: string;
  taxCode: string;

  constructor(data: any) {
    super(data);

    if (data.expirationDate) {
      this.expirationDate = moment(data.expirationDate).toDate();
    }

    if (data.delegationAcceptedRefusedDate) {
      this.delegationAcceptedRefusedDate = moment(data.delegationAcceptedRefusedDate).toDate();
    }
  }

  get serialised() {
    return {};
  }

  getGrantsGroups(): IGrantGroup[] {
    const grantsGrouped: IGrantGroup[] = [];

    if (!this.grants?.length) {
      return grantsGrouped;
    }

    this.grants.forEach(grant => {
      if (!grant.active || !grant.group || !grant.groupDescription || !grant.description) {
        return;
      }

      let grantsGroup: IGrantGroup = grantsGrouped.find(groupItem => groupItem.code === grant.group);
      if (grantsGroup) {
        grantsGroup.grants.push(grant);
      } else {
        const { group, groupDescription } = grant;
        grantsGroup = {
          code: group,
          description: groupDescription,
          grants: [grant],
        };
        grantsGrouped.push(grantsGroup);
      }
    });
    return grantsGrouped;
  }

  static getAllStatuses() {
    return Object.values(EFelStatus);
  }
}

export interface IGrant {
  active: boolean;
  code: string;
  description: string;
  group: string;
  groupDescription: string;
}

export interface IGrantGroup {
  code: string;
  description: string;
  grants: IGrant[];
}
