import { PecPermission } from '@models';
import { ApiService } from './api.service';
import { Injectable, Injector } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PecPermissionsService extends ApiService {
  constructor(override injector: Injector) {
    super('permissions/pec', PecPermission, injector);
  }
}
