import { on } from '@ngrx/store';
import { SessionActions } from './session.actions';
import { ISessionState, initialState } from './session.state';
import { createImmerReducer } from 'ngrx-immer/store';

export const sessionReducer = createImmerReducer<ISessionState>(
  initialState,
  on(SessionActions.setUser, (state, { user }) => {
    state.user = user;
    return state;
  }),
  on(SessionActions.setBreakpoints, (state, { breakpoints }) => {
    state.breakpoints = breakpoints;
    return state;
  })
);
