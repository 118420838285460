import { Injectable } from '@angular/core';
import { AppConfigService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class AppConfigUtils {
  constructor(private appConfig: AppConfigService) {}

  private get config() {
    return this.appConfig.getConfig();
  }

  isApiUrlIn(url: string) {
    return this.config?.apiUrl && url.includes(this.config.apiUrl);
  }
}
