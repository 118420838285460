import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { ToastMessagesService } from '../services';
import { AppConfigUtils } from '../utils';
import { IApiError } from '../interfaces';
import { marker } from '@ngneat/transloco-keys-manager/marker';
import { TranslocoService } from '@ngneat/transloco';
import { EAPiErrorCode } from '../enums';

/**
 * ApiErrorsInterceptor
 *
 * @export
 * @class ApiErrorsInterceptor
 * @typedef {ApiErrorsInterceptor}
 * @implements {HttpInterceptor}
 */
@Injectable()
export class ApiErrorsInterceptor implements HttpInterceptor {
  /**
   * Creates an instance of ApiErrorsInterceptor.
   *
   * @constructor
   * @param {AppConfigUtils} appConfigUtils
   * @param {ToastMessagesService} toastMessagesService
   */
  constructor(
    private appConfigUtils: AppConfigUtils,
    private toastMessagesService: ToastMessagesService,
    private translocoService: TranslocoService
  ) {}

  /**
   * Intercept http requests and add the Authorization header if the request is for the internal API
   *
   * @param {HttpRequest<any>} req
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.appConfigUtils.isApiUrlIn(req.url)) {
      return next.handle(req).pipe(
        catchError((response: HttpErrorResponse) => {
          const errorMessage = (response.error?.errors || [])
            .map((error: IApiError) => this.errorMessage(error))
            .join('\n');

          if (errorMessage) {
            this.toastMessagesService.show({
              title: errorMessage,
              type: 'error',
            });
          }

          return throwError(() => response);
        })
      );
    }
    return next.handle(req);
  }

  errorMessage(error: IApiError): string {
    return apiErrorCodeTranslation[error.code]
      ? this.translocoService.translate(apiErrorCodeTranslation[error.code])
      : this.translocoService.translate(marker('ERRORS.GENERIC_API_ERROR'), { code: error.code });
  }
}

const apiErrorCodeTranslation = {
  [EAPiErrorCode.COS_NOT_SUPERVISIONABLE]: marker('ERRORS.COS_NOT_SUPERVISIONABLE'),
  [EAPiErrorCode.DELEGATION_ALREADY_EXISTS]: marker('ERRORS.DELEGATION_ALREADY_EXISTS'),
  [EAPiErrorCode.MAX_REQUEST_REACHED]: marker('ERRORS.MAX_REQUEST_REACHED'),
  [EAPiErrorCode.DEL_001]: marker('ERRORS.DELEGATION_ALREADY_EXISTS'),
  [EAPiErrorCode.INTERNAL_INCONSISTENT_STATE]: marker('ERRORS.PERMISSION_UPGRADE_IN_PROGRESS'),
  [EAPiErrorCode.SSO_NOT_ENABLED]: marker('ERRORS.SSO_NOT_ENABLED'),
};
