import { Injectable, Injector } from '@angular/core';
import { ApiService } from './api.service';
import { Pec } from '@models';
import { Observable, map } from 'rxjs';
import { EPecStatus } from '../../enums';

@Injectable({
  providedIn: 'root',
})
export class PecService extends ApiService {
  constructor(injector: Injector) {
    super('delegation/pec', Pec, injector);
  }

  getNumberOfDelegatedPec(): Observable<number> {
    return this.get({
      page: 0,
      size: 1,
      filters: [{ property: 'status', value: [EPecStatus.DELEGATED] }],
    }).pipe(
      map(response => {
        return response.pagination.totalItems;
      })
    );
  }
}
