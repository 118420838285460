import { Injectable, Injector } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SsoService extends ApiService {
  constructor(injector: Injector) {
    super('auth/sso', null, injector);
  }

  felSso(): Observable<string> {
    return this.post<any>({
      path: '/fel',
    }).pipe(
      map(response => {
        return response.data.access_token;
      })
    );
  }

  pecSso(idUser: string): Observable<any> {
    return this.post<any>({
      path: '/pec',
      body: {
        idUser,
      },
    }).pipe(
      map(response => {
        return response.data;
      })
    );
  }
}
