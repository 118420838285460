import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { Fel } from '@models';
import { IApiResponse } from '../../interfaces';

export const FelActions = createActionGroup({
  source: 'Fel',
  events: {
    load: emptyProps(),
    loaded: props<{ response: IApiResponse<Fel[]> }>(),
    loadError: props<{ error: any }>(),
  },
});
