import { Fel } from '@models';
import { IApiResponse } from '../../interfaces';

export interface IFelState {
  response: IApiResponse<Fel[]>;
  isLoading: boolean;
  error: any;
}

export const initialState: IFelState = {
  response: null,
  isLoading: false,
  error: null,
};
