import { Injectable } from '@angular/core';

import { SessionActions } from './session.actions';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map } from 'rxjs';
import { createEffect } from '@ngrx/effects';
import { IBreakpoints } from './session.state';

@Injectable({ providedIn: 'root' })
export class SessionEffect {
  watchBreakpoints$ = createEffect(() =>
    this.bo
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
        Breakpoints.Handset,
        Breakpoints.Tablet,
        Breakpoints.Web,
        Breakpoints.HandsetPortrait,
        Breakpoints.TabletPortrait,
        Breakpoints.WebPortrait,
        Breakpoints.HandsetLandscape,
        Breakpoints.TabletLandscape,
        Breakpoints.WebLandscape,
      ])
      .pipe(
        map(() => {
          const breakpoints: IBreakpoints = {
            isXSmall: this.bo.isMatched(Breakpoints.XSmall),
            isSmall: this.bo.isMatched(Breakpoints.Small),
            isMedium: this.bo.isMatched(Breakpoints.Medium),
            isLarge: this.bo.isMatched(Breakpoints.Large),
            isXLarge: this.bo.isMatched(Breakpoints.XLarge),
            isHandset: this.bo.isMatched(Breakpoints.Handset),
            isTablet: this.bo.isMatched(Breakpoints.Tablet),
            isWeb: this.bo.isMatched(Breakpoints.Web),
            isHandsetPortrait: this.bo.isMatched(Breakpoints.HandsetPortrait),
            isTabletPortrait: this.bo.isMatched(Breakpoints.TabletPortrait),
            isWebPortrait: this.bo.isMatched(Breakpoints.WebPortrait),
            isHandsetLandscape: this.bo.isMatched(Breakpoints.HandsetLandscape),
            isTabletLandscape: this.bo.isMatched(Breakpoints.TabletLandscape),
            isWebLandscape: this.bo.isMatched(Breakpoints.WebLandscape),
          };

          if (breakpoints.isHandset || breakpoints.isTabletPortrait) {
            breakpoints.isHandsetLayout = true;
          } else {
            // (breakpoints.isTabletLandscape || breakpoints.isWeb)
            breakpoints.isHandsetLayout = false;
          }

          return SessionActions.setBreakpoints({ breakpoints });
        })
      )
  );

  constructor(private bo: BreakpointObserver) {}
}
