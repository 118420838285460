/**
 * Api sort direction enum
 *
 * @export
 * @enum {string}
 */
export enum EApiSortDirection {
  DESC = 'desc',
  ASC = 'asc',
  UNDEFINED = '',
}

export enum EAPiErrorCode {
  //pec
  COS_NOT_SUPERVISIONABLE = 'COS_NOT_SUPERVISIONABLE',
  DELEGATION_ALREADY_EXISTS = 'DELEGATION_ALREADY_EXISTS',
  INTERNAL_INCONSISTENT_STATE = 'INTERNAL_INCONSISTENT_STATE',
  SSO_NOT_ENABLED = 'SSO_NOT_ENABLED',
  //fel
  DEL_001 = 'DEL_001', //delegation already exists
  //bff
  MAX_REQUEST_REACHED = 'MAX_REQUEST_REACHED',
}
