import { SuperModel } from './super-model.model';

export class StickyBanner extends SuperModel {
  metadati: {
    dataCreazione: string;
    dataUltimaModifica: string;
  };
  tipologia: string;
  categoria: string;
  titolo: string;
  testo: string;
  immagine: string;
  coloreSfondo: string;
  coloreBordo: string;
  coloreTitolo: string;
  coloreOver: string;
  cta: {
    tipologia: 0;
    testo: string;
    link: string;
    immagine: string;
  }[];
  moduloRiferimento: string;
  posizione: 0;

  constructor(data = {}) {
    super(data);
  }

  get serialised() {
    return {};
  }
}
