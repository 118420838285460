import { on } from '@ngrx/store';
import { createImmerReducer } from 'ngrx-immer/store';

import { PecActions } from './pec.actions';
import { IPecState, initialState } from './pec.state';

export const pecReducer = createImmerReducer<IPecState>(
  initialState,
  on(PecActions.load, state => {
    state.isLoading = true;
    state.error = null;
    return state;
  }),
  on(PecActions.loaded, (state, { response }) => {
    state.isLoading = false;
    state.response = response;
    state.error = null;
    return state;
  }),
  on(PecActions.loadError, (state, { error }) => {
    state.error = error;
    state.isLoading = false;
    return state;
  })
);
