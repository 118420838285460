import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MATERIAL_MODULES, CDK_MODULES } from './material-modules';
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { RecaptchaModule } from 'ng-recaptcha';

import { TranslocoRootModule } from './transloco/transloco-root.module';

import { InfinitiveScrollTriggerComponent } from './components/infinitive-scroll-trigger/infinitive-scroll-trigger.component';
import { NewsChipComponent } from './components/news-chip/news-chip.component';
import { NotificationBarComponent } from './components/notification-bar/notification-bar.component';
import { MasterDetailComponent } from './components/master-detail/master-detail.component';
import { provideTranslocoMessageformat } from '@ngneat/transloco-messageformat';
import { NewsListDetailComponent } from '../modules/news/news-list-detail/news-list-detail.component';
import { NewsListComponent } from '../modules/news/news-list/news-list.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { FilterComponent } from './components/filter/filter.component';
import { FiltersChipsComponent } from './components/filters-chips/filters-chips.component';
import { FiltersResetButtonComponent } from './components/filters-reset-button/filters-reset-button.component';
import { StatusChipComponent } from './components/status-chip/status-chip.component';
import { InlineLoaderComponent } from './components/inline-loader/inline-loader.component';
import { StatusFilterComponent } from './components/status-filter/status-filter.component';
import { TableStatusComponent } from './components/table-status/table-status.component';
import { LoaderComponent } from './components/loader/loader.component';
import { OkCancelDialogComponent } from './components/ok-cancel-dialog/ok-cancel-dialog.component';
import { UsageIndicatorComponent } from './components/usage-indicator/usage-indicator.component';
import { ConfirmationMessageModalComponent } from './components/confirmation-message-modal/confirmation-message-modal.component';
import { ListFiltersComponent } from './components/list-filters/list-filters.component';
import { PasswordExpirationModalComponent } from './components/password-expiration-modal/password-expiration-modal.component';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { ToastMessageComponent } from './components/toast-message/toast-message.component';
import { RecaptchaComponent } from './components/recaptcha/recaptcha.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { SelectCheckAllComponent } from './components/select-check-all/select-check-all.component';

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 300,
    exitDuration: 0,
  },
};

const SHARED_MODULES = [
  RouterModule,
  CommonModule,
  TranslocoRootModule,
  ReactiveFormsModule,
  RecaptchaModule,
  CDK_MODULES,
  MATERIAL_MODULES,
];
const SHARED_COMPONENTS = [
  InfinitiveScrollTriggerComponent,
  NewsChipComponent,
  NotificationBarComponent,
  MasterDetailComponent,
  NewsListComponent,
  NewsListDetailComponent,
  PaginatorComponent,
  FilterComponent,
  FiltersChipsComponent,
  FiltersResetButtonComponent,
  StatusChipComponent,
  InlineLoaderComponent,
  StatusFilterComponent,
  TableStatusComponent,
  LoaderComponent,
  OkCancelDialogComponent,
  UsageIndicatorComponent,
  ConfirmationMessageModalComponent,
  ListFiltersComponent,
  PasswordExpirationModalComponent,
  ErrorMessageComponent,
  ToastMessageComponent,
  RecaptchaComponent,
  SelectCheckAllComponent,
];
const SHARED_DIRECTIVES = [];
const PROVIDERS = [
  { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
  MatPaginatorIntl,
  provideTranslocoMessageformat(),
];

@NgModule({
  imports: [...SHARED_MODULES],
  exports: [...SHARED_MODULES, ...SHARED_COMPONENTS, ...SHARED_DIRECTIVES],
  declarations: [...SHARED_COMPONENTS, ...SHARED_DIRECTIVES],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [...PROVIDERS],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
