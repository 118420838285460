import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, from, of } from 'rxjs';
import { map, finalize, catchError, switchMap } from 'rxjs/operators';

import { IApiPagination, IApiRequest, IApiResponse } from '@interfaces';
import { AppConfigService, AuthService, LoaderService } from '@services';
import { TranslocoService } from '@ngneat/transloco';
import { convertDateToString } from '../../utils/date-to-string.util';

export class ApiService {
  protected loaderService: LoaderService;
  protected httpClient: HttpClient;
  protected authService: AuthService;
  protected appConfigService: AppConfigService;
  protected translocoService: TranslocoService;

  constructor(
    protected apiEntity: string,
    protected entityClass: any,
    protected injector: Injector,
    protected mockApiUrl?: string
  ) {
    this.loaderService = this.injector.get(LoaderService);
    this.httpClient = this.injector.get(HttpClient);
    this.appConfigService = this.injector.get(AppConfigService);
    this.translocoService = this.injector.get(TranslocoService);
    this.authService = this.injector.get(AuthService);
  }

  get<T>(requestParams: IApiRequest = {}): Observable<IApiResponse<T[]>> {
    return this.request<T[]>({
      ...requestParams,
      method: 'GET',
    });
  }

  list<T>(requestParams: IApiRequest = {}): Observable<IApiResponse<T[]>> {
    return this.request<T[]>({
      path: '/list',
      ...requestParams,
      method: 'POST',
    });
  }

  getById<T>(requestParams: IApiRequest = {}): Observable<IApiResponse<T>> {
    return this.request<T>({ ...requestParams, method: 'GET' });
  }

  post<T>(requestParams: IApiRequest = {}): Observable<IApiResponse<T>> {
    return this.request<T>({ ...requestParams, method: 'POST' });
  }

  patch<T>(requestParams: IApiRequest = {}): Observable<IApiResponse<T>> {
    return this.request<T>({
      ...requestParams,
      method: 'PATCH',
    });
  }

  put<T>(requestParams: IApiRequest = {}): Observable<IApiResponse<T>> {
    return this.request<T>({ ...requestParams, method: 'PUT' });
  }

  delete<T>(requestParams: IApiRequest = {}): Observable<IApiResponse<T>> {
    return this.request<T>({ ...requestParams, method: 'DELETE' });
  }

  action<T>(requestParams: IApiRequest = {}): Observable<IApiResponse<T>> {
    return this.request<T>({
      ...requestParams,
      method: requestParams.body === undefined ? 'GET' : 'POST',
    });
  }

  private isResponseList<T>(response: IApiResponse<T[]>): boolean {
    return Array.isArray(response.data);
  }

  private getApiUrl(requestParams: IApiRequest = {}): string {
    return requestParams.apiUrl || this.mockApiUrl || this.appConfigService.getConfig()?.apiUrl;
  }

  /**
   * This is an Api helper that will parse the request and response, calling the this.apiUrl
   * as base endpoint
   */
  request<T>(requestParams: IApiRequest = {}): Observable<IApiResponse<T>> {
    if (requestParams.showLoader) {
      this.loaderService.show();
    }
    return this.fetch<T>(requestParams).pipe(
      map(envelope => this.mapEnvelope(envelope)),
      map(envelope => {
        return {
          ...envelope,
          data: envelope?.data
            ? !this.entityClass
              ? envelope.data
              : this.isResponseList<T>(envelope as IApiResponse<T[]>)
              ? (envelope.data as T[]).map(e => new this.entityClass(e))
              : new this.entityClass(envelope.data)
            : null,
        };
      })
    );
  }

  /**
   * This is a generic Api helper usefull to do httpClient calls to arbitrary endpoint without parsing the
   * response. Note taht this is parsing the request, so this method can call only application API's
   */
  fetch<T>(requestParams: IApiRequest = {}): Observable<IApiResponse<T>> {
    const parsedRequestParams = this.parseRequestParams(requestParams);
    let apiCall: Observable<any>;
    switch (requestParams.method) {
      case 'GET':
        apiCall = this.httpClient[requestParams.method.toLocaleLowerCase()](
          parsedRequestParams.path,
          parsedRequestParams.options
        );
        break;
      case 'DELETE':
        if (parsedRequestParams.body) {
          apiCall = this.httpClient.request(
            requestParams.method.toLocaleLowerCase(),
            parsedRequestParams.path,
            {
              body: parsedRequestParams.body,
            }
          );
        } else {
          apiCall = this.httpClient[requestParams.method.toLocaleLowerCase()](
            parsedRequestParams.path,
            parsedRequestParams.options
          );
        }

        break;
      case 'POST':
      case 'PUT':
      case 'PATCH':
        apiCall = this.httpClient[requestParams.method.toLocaleLowerCase()](
          parsedRequestParams.path,
          parsedRequestParams.body,
          parsedRequestParams.options
        );
        break;
    }

    return of(this.authService.hasValidToken()).pipe(
      switchMap(hasValidToken => (hasValidToken ? of(null) : from(this.authService.refresh()))),
      switchMap(() =>
        apiCall.pipe(
          catchError(error => {
            error.method = requestParams.method;
            error.requestBody = requestParams.body;
            error.queryParams = parsedRequestParams.options['params'];
            throw error;
          }),
          finalize(() => !requestParams.disableHideLoader && this.loaderService.hide())
        )
      )
    );
  }

  private mapEnvelope<T>(envelope): IApiResponse<T> {
    let items = [];
    let pagination: IApiPagination;

    if (envelope?.data?.items) {
      items = envelope.data.items;
    } else {
      return envelope;
    }

    if (envelope?.data?.pagination) {
      const { pageIndex, pageSize, totalElements, totalPages } = envelope.data.pagination;
      pagination = {
        page: pageIndex - 1,
        size: pageSize,
        totalItems: totalElements,
        totalPages,
      };
    }

    return {
      ...envelope,
      data: items,
      pagination: pagination || undefined,
    };
  }

  parseRequestParams(requestParams: IApiRequest = {}) {
    return {
      path: this.buildRequestPath(requestParams),
      options: this.mapRequestOptions(requestParams),
      body: this.mapRequestBody(requestParams),
    };
  }

  buildRequestPath(requestParams: IApiRequest) {
    let path = `${this.getApiUrl()}/${this.apiEntity}`;

    let id = requestParams.id;
    if (id === undefined) {
      id = requestParams.body?.id;
    }
    if (id) {
      path += `/${id}`;
    }

    if (requestParams.path) {
      path += requestParams.path;
    }

    return path;
  }

  mapRequestOptions(requestParams: IApiRequest) {
    const { page, size, queryParams } = requestParams;
    const params: any = {};
    const { sorting, filters } = requestParams;

    if (requestParams.method !== 'GET') {
      requestParams.body = requestParams.body || {};
    }

    if (page !== undefined) {
      params.page = page + 1;
    }

    if (size !== undefined) {
      params.size = size;
    }

    if (filters?.length) {
      const filtersString = filters
        .map(filter => {
          let value = filter.value;
          if (value instanceof Date) {
            value = convertDateToString(value);
          }
          return `${filter.property}=${value}`;
        })
        .join(';');
      params.filters = filtersString;
    }

    if (queryParams) {
      Object.assign(params, queryParams);
    }

    if (sorting && sorting.length) {
      const sortString = sorting
        .map(sort => {
          const { property, direction } = sort;
          return `${property}=${direction.toUpperCase()}`;
        })
        .join(',');

      params.sort = sortString;
    }
    return { params };
  }

  mapRequestBody(requestParams: IApiRequest) {
    let body = requestParams.body;
    if (!body) {
      return null;
    }

    if (body.length && body[0].serialised) {
      body = body.map(item => item.serialised);
    }

    if (!body.length && body.serialised) {
      body = body.serialised;
    }

    return body;
  }
}
