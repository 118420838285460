import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthService } from '../services';
import { AppConfigUtils } from '../utils';

export const EXCLUDE_OAUTH_INTERCEPTOR_HEADER = 'EXCLUDE_OAUTH_INTERCEPTOR';
/**
 * OAuthInterceptor
 *
 * @export
 * @class OAuthInterceptor
 * @typedef {OAuthInterceptor}
 * @implements {HttpInterceptor}
 */
@Injectable()
export class OAuthInterceptor implements HttpInterceptor {
  /**
   * Creates an instance of OAuthInterceptor.
   *
   * @constructor
   * @param {AuthService} authService
   * @param {AppConfigUtils} appConfigUtils
   */
  constructor(private readonly authService: AuthService, private readonly appConfigUtils: AppConfigUtils) {}

  /**
   * Intercept http requests and add the Authorization header if the request is for the internal API
   *
   * @param {HttpRequest<any>} req
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      this.authService.hasValidToken() &&
      this.appConfigUtils.isApiUrlIn(req.url) &&
      !req.headers.has(EXCLUDE_OAUTH_INTERCEPTOR_HEADER)
    ) {
      const authReq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${this.authService.accessToken}`),
      });
      return next.handle(authReq);
    }
    return next.handle(req);
  }
}
