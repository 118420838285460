import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { News } from '@models';
import { IApiResponse } from '../../interfaces';

export const NewsActions = createActionGroup({
  source: 'News',
  events: {
    setResponse: props<{ response: IApiResponse<News[]> }>(),
    setCachedResponse: props<{ response: IApiResponse<News[]> }>(),
    load: emptyProps(),
    loaded: props<{ response: IApiResponse<News[]> }>(),
    loadError: props<{ error: any }>(),
    markAsReadUnRead: props<{ news: News; read: boolean }>(),
    update: props<{ news: News }>(),
    setMarkAsReadUnReadError: props<{ error: any }>(),
  },
});
