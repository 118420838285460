import { Injectable, Injector } from '@angular/core';
import { ApiService } from './api.service';

import { Changelog } from '@models';
import { IApiRequest, IApiResponse } from '../../interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChangelogService extends ApiService {
  constructor(injector: Injector) {
    super('changelog', Changelog, injector);
  }

  override get<Changelog>(requestParams: IApiRequest = {}): Observable<IApiResponse<Changelog[]>> {
    return super.get<Changelog>({
      queryParams: {
        distributionChannelId: 1,
        languageCode: this.translocoService.getActiveLang()?.toUpperCase(),
      },
      ...requestParams,
    });
  }
}
