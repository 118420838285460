import { on } from '@ngrx/store';
import { FelActions } from './fel.actions';
import { IFelState, initialState } from './fel.state';
import { createImmerReducer } from 'ngrx-immer/store';

export const felReducer = createImmerReducer<IFelState>(
  initialState,
  on(FelActions.load, state => {
    state.isLoading = true;
    state.error = null;
    return state;
  }),
  on(FelActions.loaded, (state, { response }) => {
    state.isLoading = false;
    state.response = response;
    state.error = null;
    return state;
  }),
  on(FelActions.loadError, (state, { error }) => {
    state.error = error;
    state.isLoading = false;
    return state;
  })
);
