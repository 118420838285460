import { Injectable, Injector } from '@angular/core';
import { ApiService } from './api.service';
import { Fel } from '@models';

@Injectable({
  providedIn: 'root',
})
export class FelService extends ApiService {
  constructor(injector: Injector) {
    super('delegation/fel', Fel, injector);
  }
}
