import { News } from '@models';
import { IApiResponse } from '../../interfaces';

/**
 * Representation of news state in store
 * @date 9/12/2023 - 8:05:27 PM
 *
 * @export
 * @interface INewsState
 * @typedef {INewsState}
 */
export interface INewsState {
  /**
   * Description placeholder
   * @date 15/09/2023 - 16:06:50
   *
   * @type {IApiResponse<News[]>}
   */
  cachedResponse: IApiResponse<News[]>;
  /**
  /**
   * Response from API
   * @date 9/12/2023 - 8:05:27 PM
   *
   * @type {IApiResponse<News[]>}
   */
  response: IApiResponse<News[]>;
  /**
   * Loading flag
   * @date 9/12/2023 - 8:05:27 PM
   *
   * @type {boolean}
   */
  isLoading: boolean;
  /**
   * API error
   * @date 9/12/2023 - 8:05:27 PM
   *
   * @type {*}
   */
  error: any;
  /**
   * API error on markAsReadUnRead action
   * @date 9/19/2023 - 11:02:17 PM
   *
   * @type {*}
   */
  markAsReadUnreadError: any;
}

/**
 * Initial state of News feature
 * @date 9/12/2023 - 8:05:27 PM
 *
 * @type {INewsState}
 */
export const initialState: INewsState = {
  cachedResponse: null,
  response: null,
  isLoading: false,
  error: null,
  markAsReadUnreadError: null,
};
