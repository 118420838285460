import { NgModule, isDevMode } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { FelEffect } from './fel/fel.effect';
import { felFeature } from './fel/fel.feature';
import { SessionEffect } from './session/session.effect';
import { sessionFeature } from './session/session.feature';
import { apiCallStateFeature } from './api-call-state/api-call-state.feature';
import { newsFeature } from './news/news.feature';
import { NewsEffect } from './news/news.effect';
import { pecFeature } from './pec/pec.feature';
import { PecEffect } from './pec/pec.effect';

@NgModule({
  imports: [
    StoreModule.forRoot({}),
    StoreModule.forFeature(felFeature),
    StoreModule.forFeature(sessionFeature),
    StoreModule.forFeature(apiCallStateFeature),
    StoreModule.forFeature(newsFeature),
    StoreModule.forFeature(pecFeature),
    EffectsModule.forRoot([]),
    EffectsModule.forFeature(FelEffect),
    EffectsModule.forFeature(SessionEffect),
    EffectsModule.forFeature(NewsEffect),
    EffectsModule.forFeature(PecEffect),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
  ],
})
export class StateModule {}
