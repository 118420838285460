import { Injectable, Injector } from '@angular/core';
import { ApiService } from './api.service';
import { IApiResponse } from '../../interfaces';
import { Observable } from 'rxjs';
import { Dashboard } from '../../models/dashboard.model';

@Injectable({
  providedIn: 'root',
})
export class DashboardService extends ApiService {
  constructor(injector: Injector) {
    super('dashboard', Dashboard, injector);
  }

  getDashboardData(): Observable<IApiResponse<Dashboard>> {
    return this.request<Dashboard>({ method: 'GET' });
  }
}
