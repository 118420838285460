import { on } from '@ngrx/store';
import { NewsActions } from './news.actions';
import { INewsState, initialState } from './news.state';
import { createImmerReducer } from 'ngrx-immer/store';

/**
 * News feature reducer
 * @date 9/12/2023 - 8:08:38 PM
 *
 * @type {*}
 */
export const newsReducer = createImmerReducer<INewsState>(
  initialState,
  on(NewsActions.setCachedResponse, (state, { response }) => {
    state.cachedResponse = response;
    return state;
  }),
  on(NewsActions.setResponse, (state, { response }) => {
    state.response = response;
    return state;
  }),
  on(NewsActions.load, state => {
    state.isLoading = true;
    state.error = null;
    return state;
  }),
  on(NewsActions.loaded, (state, { response }) => {
    state.isLoading = false;
    state.response = response;
    state.error = null;
    return state;
  }),
  on(NewsActions.loadError, (state, { error }) => {
    state.error = error;
    state.isLoading = false;
    return state;
  }),
  on(NewsActions.markAsReadUnRead, state => {
    state.markAsReadUnreadError = null;
    return state;
  }),
  on(NewsActions.update, (state, { news }) => {
    const indexOfCachedNews = state.cachedResponse.data.findIndex(item => item.id === news.id);
    if (indexOfCachedNews !== -1) {
      state.cachedResponse.data[indexOfCachedNews] = news;
    }

    const indexOfNewsInResponse = state.response.data.findIndex(item => item.id === news.id);
    if (indexOfNewsInResponse !== -1) {
      state.response.data[indexOfNewsInResponse] = news;
    }
    return state;
  }),
  on(NewsActions.setMarkAsReadUnReadError, (state, { error }) => {
    state.markAsReadUnreadError = error;
    return state;
  })
);
