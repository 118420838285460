import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { User } from '@models';
import { IBreakpoints } from './session.state';

export const SessionActions = createActionGroup({
  source: 'Session',
  events: {
    setUser: props<{ user: User }>(),
    setBreakpoints: props<{ breakpoints: IBreakpoints }>(),
  },
});
