import { SuperModel } from './super-model.model';

export class FelPermission extends SuperModel {
  code: string;
  codeDescription: string;
  group: string;
  groupDescription: string;
  active: boolean;
  require: string[];

  constructor(data: any = {}) {
    super(data);
  }

  get serialised() {
    return {};
  }
}
