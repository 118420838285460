import { Component } from '@angular/core';

/**
 * InlineLoaderComponent
 *
 * @export
 * @class InlineLoaderComponent
 * @typedef {InlineLoaderComponent}
 */
@Component({
  selector: 'aup-inline-loader',
  templateUrl: './inline-loader.component.html',
  styleUrls: ['./inline-loader.component.scss'],
})
export class InlineLoaderComponent {
  constructor() {}
}
