/**
 * User status enum
 *
 * @export
 * @enum {string}
 */
export enum EUserStatus {
  ATTIVO = 'ATTIVO',
  SOSPESO = 'SOSPESO',
}
