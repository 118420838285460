import { createFeature } from '@ngrx/store';

import { newsReducer } from './news.reducer';

/**
 * News feature creator
 * @date 9/12/2023 - 8:08:53 PM
 *
 * @type {*}
 */
export const newsFeature = createFeature({
  name: 'news',
  reducer: newsReducer,
});
