import { Component, Input, OnInit } from '@angular/core';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { ApiCallStateActions } from '@core/state/api-call-state/api-call-state.actions';
import { EApiCallStateKey, EApiRequestPartKeys } from '@core/state/api-call-state/api-call-state.enum';

import { IApiPagination } from '@interfaces';
import { marker } from '@ngneat/transloco-keys-manager/marker';
import { TranslocoService } from '@ngneat/transloco';

/**
 * PaginatorComponent
 * @date 9/2/2023 - 5:07:30 PM
 *
 * @export
 * @class PaginatorComponent
 * @typedef {PaginatorComponent}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'aup-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit {
  /**
   * ApiCallStateKey
   * @date 9/2/2023 - 5:07:30 PM
   *
   * @type {EApiCallStateKey}
   */
  @Input() apiCallStateKey: EApiCallStateKey;
  /**
   * CSS class to be added to container
   * @date 9/2/2023 - 5:07:30 PM
   *
   * @type {string}
   */
  @Input() containerClass: string = '';

  /**
   * Page size options displayed on pagination component
   * @date 9/2/2023 - 5:07:30 PM
   *
   * @type {{number[]}}
   */
  @Input()
  paginationSizeOptions: number[] = [10, 20, 50];

  /**
   * Pagination definition
   * @date 9/2/2023 - 5:07:30 PM
   *
   * @type {IApiPagination}
   */
  @Input()
  pagination: IApiPagination;

  @Input()
  showPageSize: boolean = true;

  @Input()
  autoScrollSelector: string;

  @Input()
  showFirstLastButtons: boolean = true;
  /**
   * Creates an instance of PaginatorComponent.
   * @date 9/2/2023 - 5:07:30 PM
   *
   * @constructor
   * @param {Store} store$
   */
  constructor(
    private store$: Store,
    public matPaginatorIntl: MatPaginatorIntl,
    private translocoService: TranslocoService
  ) {}

  /**
   * OnInit
   * @date 9/2/2023 - 5:07:30 PM
   *
   */
  ngOnInit() {
    this.translocoService
      .selectTranslate([
        'PAGINATOR.ITEMS_PER_PAGE',
        'PAGINATOR.FIRST_PAGE',
        'PAGINATOR.LAST_PAGE',
        'PAGINATOR.NEXT_PAGE',
        'PAGINATOR.PREV_PAGE',
      ])
      .subscribe(translations => {
        this.matPaginatorIntl.itemsPerPageLabel = translations[0];
        this.matPaginatorIntl.firstPageLabel = translations[1];
        this.matPaginatorIntl.lastPageLabel = translations[2];
        this.matPaginatorIntl.nextPageLabel = translations[3];
        this.matPaginatorIntl.previousPageLabel = translations[4];
      });
  }
  /**
   * Listen for event PageEvent and update pagination in store
   * @date 9/2/2023 - 5:07:30 PM
   *
   * @param {PageEvent} { pageIndex, pageSize }
   */
  onPageChange({ pageIndex, pageSize }: PageEvent) {
    if (this.autoScrollSelector) {
      const element = document.querySelector(this.autoScrollSelector);
      element.scrollTo({ top: 0, behavior: 'smooth' });
    }

    this.store$.dispatch(
      ApiCallStateActions.updateRequestPart({
        apiCallStateKey: this.apiCallStateKey,
        requestPartKey: EApiRequestPartKeys.PAGINATION,
        shouldReplaceData: false,
        data: { page: pageIndex, size: pageSize },
      })
    );

    this.store$.dispatch(ApiCallStateActions.load({ apiCallStateKey: this.apiCallStateKey }));
  }
}
