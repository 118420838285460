<div class="flex items-center p-4">
  <div class="w-6 h-6 mr-4">
    <mat-icon *ngIf="data.type==='error'"
      svgIcon="error"
      class="!w-6 !h-6"></mat-icon>
  </div>
  <p class="text-sm"
    [innerHTML]="messageToDisplay"></p>
  <div class="w-6 h-6 ml-4">
    <mat-icon svgIcon="close"
      class="!w-6 !h-6 cursor-pointer"
      (click)="snackBarRef.dismiss()"
      (keydown)="snackBarRef.dismiss()"></mat-icon>
  </div>
</div>