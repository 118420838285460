<div class="flex flex-col w-full">

  <div class="aup-background-gray px-6 flex items-center justify-between">
    <h2 class="!p-0 w-2/3 md:w-auto"
      mat-dialog-title>{{'PASSWORD_EXPIRED_MODAL.TITLE' | transloco}}</h2>
    <button *ngIf="!data.blockCloseButtons"
      type="button"
      (click)="onClose()"
      mat-icon-button>
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>

  <div class="flex flex-col pb-4">
    <aup-notification-bar class="mt-2 px-6"
      title="{{'PASSWORD_EXPIRED_MODAL.NOTIFICATION.TITLE' | transloco }}"
      [message]="'PASSWORD_EXPIRED_MODAL.NOTIFICATION.MESSAGE' | transloco:{date:(data.expirationDate | date:'shortDate')}"
      [dismissable]="false"></aup-notification-bar>

    <div>
      <mat-divider></mat-divider>
    </div>

    <div *ngIf="!data.blockCloseButtons"
      class="mt-4 px-6">
      <mat-checkbox [formControl]="dontShowCheckbox">
        <span [innerHTML]="'PASSWORD_EXPIRED_MODAL.DONT_SHOW_DIALOG' | transloco"></span>
      </mat-checkbox>
    </div>

    <div class="flex !justify-start !mt-4 !px-6"
      mat-dialog-actions>
      <button mat-flat-button
        color="primary"
        (click)="onChangePassword()">
        {{'COMMON.BUTTON.CHANGE_PASSWORD' | transloco}}
      </button>
      <button [disabled]="data.blockCloseButtons"
        (click)="onClose()"
        mat-flat-button
        color="secondary">{{'COMMON.BUTTON.CLOSE' | transloco}}</button>
    </div>

  </div>
</div>