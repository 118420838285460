import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import { EApiCallStateKey, EApiRequestPartKeys } from '@core/state/api-call-state/api-call-state.enum';
import { ApiCallStateActions } from '@core/state/api-call-state/api-call-state.actions';

/**
 * FiltersResetButtonComponent
 * @date 9/2/2023 - 5:06:27 PM
 *
 * @export
 * @class FiltersResetButtonComponent
 * @typedef {FiltersResetButtonComponent}
 */
@Component({
  selector: 'aup-filters-reset-button',
  templateUrl: './filters-reset-button.component.html',
  styleUrls: ['./filters-reset-button.component.scss'],
})
export class FiltersResetButtonComponent {
  /**
   * ApiCallStateKey
   * @date 9/2/2023 - 5:06:27 PM
   *
   * @type {EApiCallStateKey}
   */
  @Input() apiCallStateKey: EApiCallStateKey;

  /**
   * Creates an instance of FiltersResetButtonComponent.
   * @date 9/2/2023 - 5:06:27 PM
   *
   * @constructor
   * @param {Store} store$
   */
  constructor(private store$: Store) {}

  /**
   * Reset all filters values in store
   * @date 9/2/2023 - 5:06:27 PM
   */
  resetAllFilters() {
    this.store$.dispatch(
      ApiCallStateActions.resetAllFilters({
        apiCallStateKey: this.apiCallStateKey,
      })
    );

    this.store$.dispatch(
      ApiCallStateActions.updateRequestPart({
        apiCallStateKey: this.apiCallStateKey,
        requestPartKey: EApiRequestPartKeys.PAGINATION,
        data: { page: 0 },
      })
    );

    this.store$.dispatch(ApiCallStateActions.load({ apiCallStateKey: this.apiCallStateKey }));
  }
}
