import { Pec } from '@models';
import { IApiResponse } from '../../interfaces';

export interface IPecState {
  response: IApiResponse<Pec[]>;
  isLoading: boolean;
  error: any;
}

export const initialState: IPecState = {
  response: null,
  isLoading: false,
  error: null,
};
