import { Injectable } from '@angular/core';
import { TranslocoService, getBrowserLang } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class TranslocoLangDetectorService {
  constructor(private translocoService: TranslocoService) {}

  setBrowserLangIfNotSelected() {
    const selectedLang = this.getSelectedLang();
    if (selectedLang) {
      return;
    }

    const browserLang = getBrowserLang();
    const availableLanguages = this.translocoService.getAvailableLangs() as string[];

    if (!availableLanguages.includes(browserLang)) {
      return;
    }

    this.translocoService.setActiveLang(browserLang);
  }

  private getSelectedLang(): string | null {
    return localStorage.getItem('translocoLang') || null;
  }
}
