import { createActionGroup, props } from '@ngrx/store';
import { EApiCallStateKey, EApiRequestPartKeys } from './api-call-state.enum';
import { IApiCallState } from './api-call-state.interface';

export const ApiCallStateActions = createActionGroup({
  source: 'ApiCallState',
  events: {
    load: props<{ apiCallStateKey: EApiCallStateKey }>(),
    replace: props<{ apiCallState: IApiCallState }>(),
    updateRequestPart: props<{
      apiCallStateKey: EApiCallStateKey;
      requestPartKey: EApiRequestPartKeys;
      data: any;
      shouldReplaceData?: boolean;
    }>(),
    resetAllFilters: props<{
      apiCallStateKey: EApiCallStateKey;
    }>(),
    resetPagination: props<{
      apiCallStateKey: EApiCallStateKey;
    }>(),
    updateRequestPartDataSlice: props<{
      apiCallStateKey: EApiCallStateKey;
      requestPartKey: EApiRequestPartKeys;
      dataSliceKey: string;
      data: any;
      shouldReplaceData?: boolean;
    }>(),
  },
});

export type ApiCallStateActionsType =
  | ReturnType<typeof ApiCallStateActions.load>
  | ReturnType<typeof ApiCallStateActions.replace>
  | ReturnType<typeof ApiCallStateActions.updateRequestPart>
  | ReturnType<typeof ApiCallStateActions.resetAllFilters>
  | ReturnType<typeof ApiCallStateActions.updateRequestPartDataSlice>;
