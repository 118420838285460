import { Injectable, Injector } from '@angular/core';

import sortArray from 'sort-array';

import { NewsService } from '@services';
import { NewsActions } from './news.actions';

import { EApiCallStateKey, EApiRequestPartKeys } from '../api-call-state/api-call-state.enum';
import { News } from '@models';

import { SuperApiCallStateEffect } from '../effects/super-api-call-state.effect';
import { IApiRequest, IApiResponse } from '@interfaces';
import { IApiFilter, IApiFilterOption, IApiFilterUI } from '../../interfaces/filters.interface';
import { ApiCallStateActions } from '../api-call-state/api-call-state.actions';
import { catchError, map, of, switchMap, tap, take } from 'rxjs';
import { createEffect, ofType } from '@ngrx/effects';
import { applyFilters, applyPagination, applySort } from '../effects/client-side-processor';
import { IApiCallState } from '../api-call-state/api-call-state.interface';
import { selectCachedResponse } from './news.selectors';
import { ApiCallState } from '../api-call-state/api-call-state.reducer';
import clone from 'just-clone';

/**
 * News api call state effect
 * @date 9/12/2023 - 8:10:11 PM
 *
 * @export
 * @class NewsEffect
 * @typedef {NewsEffect}
 * @extends {SuperApiCallStateEffect<News[]>}
 */
@Injectable({ providedIn: 'root' })
export class NewsEffect extends SuperApiCallStateEffect<News[]> {
  /**
   * Key of api call state
   * @date 9/12/2023 - 8:10:11 PM
   *
   * @type {*}
   */
  apiCallStateKey = EApiCallStateKey.NEWS;
  /**
   * Actions of news feature
   * @date 9/12/2023 - 8:10:11 PM
   *
   * @type {*}
   */
  featureActions = NewsActions;

  /**
   * Creates an instance of NewsEffect.
   * @date 9/12/2023 - 8:10:11 PM
   *
   * @constructor
   * @param {NewsService} service
   * @param {Injector} injector
   */

  markAsReadUnReadEffect$;

  constructor(private service: NewsService, injector: Injector) {
    super(injector);
    this.initMarkAsReadUnreadEffect();
  }

  initMarkAsReadUnreadEffect() {
    this.markAsReadUnReadEffect$ = createEffect(() =>
      this.actions$.pipe(
        ofType(NewsActions.markAsReadUnRead),
        switchMap(action => {
          const { news, read } = action;
          return this.service.put({ id: news.id, body: { read }, path: '/read' }).pipe(
            map(() => {
              this.store$.dispatch(NewsActions.setMarkAsReadUnReadError({ error: null }));
              return NewsActions.update({ news: new News({ ...news, isReaded: read }) });
            })
          );
        }),
        catchError(error => {
          return of(NewsActions.setMarkAsReadUnReadError({ error: error.message }));
        })
      )
    );
  }
  /**
   * News service api call
   * @date 9/12/2023 - 8:10:11 PM
   *
   * @param {IApiRequest} requestParams
   * @returns {*}
   */
  callApi(requestParams: IApiRequest, apiCallState: IApiCallState) {
    return this.store$.select(selectCachedResponse).pipe(
      take(1),
      switchMap(cachedResponse => {
        if (cachedResponse) {
          return of(cachedResponse);
        }

        return this.service.get<News>().pipe(
          tap(response => {
            this.store$.dispatch(NewsActions.setCachedResponse({ response }));
          })
        );
      }),
      // NOTE: we must extract filters before to filter to have them all
      tap((response: IApiResponse<News[]>) => {
        this.populateCategoryFilter(response, apiCallState);
      }),
      // NOTE: Apply filters
      map((response: IApiResponse<News[]>) => {
        return { ...response, data: applyFilters(response.data, apiCallState) };
      }),
      // NOTE: Apply sorting
      map((response: IApiResponse<News[]>) => {
        return { ...response, data: applySort(response.data, apiCallState) };
      }),
      // NOTE: Create pagination node part with client side data
      map((response: IApiResponse<News[]>) => {
        return {
          ...response,
          pagination: {
            page: requestParams.page,
            size: requestParams.size,
            totalItems: response.data?.length,
          },
        } as IApiResponse<News[]>;
      }),
      // NOTE: Apply pagination
      map((response: IApiResponse<News[]>) => {
        return { ...response, data: applyPagination(response.data, apiCallState) };
      })
    );
  }

  /**
   * Populate category filter values base on api response
   * @date 9/12/2023 - 8:10:11 PM
   *
   * @private
   * @param {IApiResponse<News[]>} response
   */
  private populateCategoryFilter(response: IApiResponse<News[]>, apiCallState: IApiCallState) {
    let filterOptions: IApiFilterOption[] = [];

    if (response?.data?.length) {
      filterOptions = this.getCategoryFilterOptions(response.data);
    }

    const categoryFilter: IApiFilter = apiCallState.requestParts.find(
      requestPart => requestPart.key === EApiRequestPartKeys.FILTERS
    ).data.category;

    const ui = clone(categoryFilter.ui);
    ui['default'].options = filterOptions;

    const chips = clone(categoryFilter.chips);
    chips.options = filterOptions;

    this.store$.dispatch(
      ApiCallStateActions.updateRequestPartDataSlice({
        apiCallStateKey: EApiCallStateKey.NEWS,
        requestPartKey: EApiRequestPartKeys.FILTERS,
        dataSliceKey: 'category',
        data: { ui, chips },
      })
    );
  }

  /**
   * Convert news to category filter options
   * @date 9/12/2023 - 8:10:11 PM
   *
   * @private
   * @param {News[]} news
   * @returns { IApiFilterOption[]}
   */
  private getCategoryFilterOptions(news: News[]): IApiFilterOption[] {
    const allCategories: string[] = [];
    news.forEach(news => {
      if (!allCategories.includes(news.category)) {
        allCategories.push(news.category);
      }
    });

    if (!allCategories.length) {
      return [];
    }

    sortArray(allCategories, { order: 'asc' });

    return allCategories.map(category => {
      return {
        label: category,
        value: category,
      };
    });
  }
}
