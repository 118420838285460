import { IApiCallState } from './api-call-state.interface';
import { createImmerReducer } from 'ngrx-immer/store';
import { initialApiCallState } from './initial-state';
import { on } from '@ngrx/store';

import { ApiCallStateActions } from './api-call-state.actions';
import { EApiRequestPartKeys } from './api-call-state.enum';
import { IApiFilter } from '@core/interfaces/filters.interface';

export interface ApiCallState {
  apiCallState: IApiCallState[];
}

export const apiCallStateReducer = createImmerReducer<ApiCallState>(
  initialApiCallState,
  on(ApiCallStateActions.load, (state, action) => state),
  on(ApiCallStateActions.updateRequestPart, (state, action) => {
    const { apiCallStateKey, requestPartKey, data, shouldReplaceData = false } = action;

    const apiCallState = state.apiCallState.find(apiCall => apiCall.key === apiCallStateKey);

    const requestPart = apiCallState.requestParts.find(reqPart => reqPart.key === requestPartKey);

    if (shouldReplaceData) {
      requestPart.data = data;
    } else {
      requestPart.data = { ...requestPart.data, ...data };
    }

    return state;
  }),
  on(ApiCallStateActions.updateRequestPartDataSlice, (state, action) => {
    const { apiCallStateKey, requestPartKey, data, dataSliceKey, shouldReplaceData = false } = action;

    const apiCallState = state.apiCallState.find(apiCall => apiCall.key === apiCallStateKey);

    const requestPart = apiCallState.requestParts.find(reqPart => reqPart.key === requestPartKey);

    if (shouldReplaceData) {
      requestPart.data[dataSliceKey] = data;
    } else {
      requestPart.data[dataSliceKey] = { ...requestPart.data[dataSliceKey], ...data };
    }

    return state;
  }),
  on(ApiCallStateActions.resetAllFilters, (state, action) => {
    const { apiCallStateKey } = action;

    const apiCallState = state.apiCallState.find(apiCall => apiCall.key === apiCallStateKey);

    const filtersRequestPart = apiCallState.requestParts.find(
      reqPart => reqPart.key === EApiRequestPartKeys.FILTERS
    );

    if (filtersRequestPart.data) {
      Object.values(filtersRequestPart.data).forEach((filter: IApiFilter) => {
        filter.value = filter.defaultValue;
      });
    }
    return state;
  }),
  on(ApiCallStateActions.replace, (state, action) => {
    const { apiCallState } = action;
    const apiCallStateIndex = state.apiCallState.findIndex(item => item.key === apiCallState.key);
    state.apiCallState[apiCallStateIndex].requestParts = apiCallState.requestParts;
    return state;
  }),
  on(ApiCallStateActions.resetPagination, (state, action) => {
    const { apiCallStateKey } = action;

    const apiCallState = state.apiCallState.find(apiCall => apiCall.key === apiCallStateKey);

    const paginationRequestPart = apiCallState.requestParts.find(
      reqPart => reqPart.key === EApiRequestPartKeys.PAGINATION
    );

    paginationRequestPart.data = {
      page: 0,
      size: 10,
      totalItems: 0,
    };

    return state;
  })
);
