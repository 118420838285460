import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PasswordExpirationModalComponent } from '../../shared/components/password-expiration-modal/password-expiration-modal.component';
import { Store } from '@ngrx/store';
import { selectUser } from '../state/session/session.selectors';
import { calculateDaysDifference } from '@core/utils';
import { AppConfigService } from './app-config.service';
import { IDialogResult } from '../interfaces';
import { EDialogResultAction } from '../enums';
import { Subscription } from 'rxjs';

/**
 * PasswordExpirationService
 * @date 10/26/2023 - 2:00:18 PM
 *
 * @export
 * @class PasswordExpirationService
 * @typedef {PasswordExpirationService}
 */
@Injectable({
  providedIn: 'root',
})
export class PasswordExpirationService {
  /**
   * Key on local storage where is stored information when user click "don`t show again"
   * @date 10/26/2023 - 2:00:18 PM
   *
   * @type {string}
   */
  localStorageSkipPasswordKey = 'USER_SKIP_PASSWORD_EXPIRATION_REMINDER';

  /**
   * Subscription
   * @date 10/26/2023 - 2:00:18 PM
   *
   * @type {Subscription}
   */
  subscription: Subscription = new Subscription();

  /**
   * Creates an instance of PasswordExpirationService.
   * @date 10/26/2023 - 2:00:18 PM
   *
   * @constructor
   * @param {MatDialog} dialogService
   * @param {Store} store$
   * @param {AppConfigService} appConfigService
   */
  constructor(
    private dialogService: MatDialog,
    private store$: Store,
    private appConfigService: AppConfigService
  ) {}

  /**
   * Initialize store listener for user changes
   * @date 10/26/2023 - 2:00:18 PM
   *
   * @private
   */
  init() {
    this.subscription.add(
      this.store$.select(selectUser).subscribe(user => {
        if (!user || !user.passwordExpirationTs) {
          return;
        }

        const { passwordExpirationTs } = user;
        const expirationDate = this.convertDate(passwordExpirationTs);

        const differenceInDays = calculateDaysDifference(new Date(), expirationDate);

        if (differenceInDays <= 0) {
          this.removeFromLocalStorage();
          this.showModal(expirationDate, true);
          return;
        }

        if (user.showExpirationAlert && !this.userSkipModal()) {
          this.showModal(expirationDate, false);
          return;
        }
      })
    );
  }

  /**
   * Display modal
   * @date 10/26/2023 - 2:00:18 PM
   *
   * @private
   * @param {Date} expirationDate
   * @param {boolean} blockCloseButtons
   */
  private showModal(expirationDate: Date, blockCloseButtons: boolean) {
    const accountManagementUrl = this.appConfigService.getConfig().accountManagementUrl;

    const dialogRef = this.dialogService.open(PasswordExpirationModalComponent, {
      maxHeight: '100vh',
      maxWidth: '100vw',
      disableClose: true,
      closeOnNavigation: false,
      data: { expirationDate, blockCloseButtons, accountManagementUrl },
    });

    dialogRef.beforeClosed().subscribe((result: IDialogResult) => {
      this.subscription.unsubscribe();
      if (result?.action === EDialogResultAction.SKIP_PASSWORD_EXPIRED_REMINDER) {
        localStorage.setItem(this.localStorageSkipPasswordKey, '1');
      }
    });
  }

  /**
   * Check if user clicked "don`t show again" in the past
   * @date 10/26/2023 - 2:00:18 PM
   *
   * @private
   * @returns {boolean}
   */
  private userSkipModal() {
    return !!localStorage.getItem(this.localStorageSkipPasswordKey);
  }

  /**
   * Remove USER_SKIP_PASSWORD_EXPIRATION_REMINDER from local storage
   * @date 10/26/2023 - 2:00:18 PM
   *
   * @private
   */
  private removeFromLocalStorage() {
    if (localStorage.getItem(this.localStorageSkipPasswordKey)) {
      localStorage.removeItem(this.localStorageSkipPasswordKey);
    }
  }

  /**
   * Create date object from string in format dd-MM-yyyy HH:mm:ss
   * @date 10/26/2023 - 2:24:13 PM
   *
   * @private
   * @param {string} date
   * @returns {*}
   */
  private convertDate(date: string) {
    const dateParts = date.split('-');
    const day = +dateParts[0];
    const month = +dateParts[1];

    const yearHourParts = dateParts[2].split(' ');
    const year = +yearHourParts[0];

    const hoursMinutesSeconds = yearHourParts[1].split(':');
    const hour = +hoursMinutesSeconds[0];
    const minute = +hoursMinutesSeconds[1];
    const second = +hoursMinutesSeconds[2];

    return new Date(year, month - 1, day, hour, minute, second);
  }
}
