/**
 *  Enum for AppNotificationType
 *
 * @export
 * @enum {string}
 */
export enum EAppNotificationType {
  SUCCESS = 'SUCCESS',
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export enum E2FAStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum EPecCertificationType {
  PEC = 'PEC',
  PECPLUS = 'PECPLUS',
}

export enum EDialogCloseStatus {
  OK = 'OK',
  CANCEL = 'CANCEL',
}
