import { EAppNotificationType } from '@/src/app/core/enums';
import { Component, Input } from '@angular/core';

/**
 * NotificationBarComponent
 *
 * @export
 * @class NotificationBarComponent
 * @typedef {NotificationBarComponent}
 */
@Component({
  selector: 'aup-notification-bar',
  templateUrl: './notification-bar.component.html',
  styleUrls: ['./notification-bar.component.scss'],
})
export class NotificationBarComponent {
  /**
   * Notification title
   *
   * @type {string}
   */
  @Input() title: string;
  /**
   * Notification message
   *
   * @type {string}
   */
  @Input({ required: true }) message: string;
  /**
   * Type of notification
   *
   * @type {EAppNotificationType}
   */
  @Input() type: EAppNotificationType = EAppNotificationType.INFO;
  /**
   * Call to action url
   *
   * @type {string}
   */
  @Input() ctaUrl: string;
  /**
   * Call to action query params
   *
   * @type {string}
   */
  @Input() ctaQueryParams: any;
  /**
   * Dismissable notification
   *
   * @type {boolean}
   */
  @Input() dismissable = false;
  /**
   * Is visible
   *
   * @type {boolean}
   */
  isVisible = true;

  /**
   * Creates an instance of NotificationBarComponent.
   *
   * @constructor
   */
  constructor() {}

  /**
   * NgOnInit lifecycle hook
   */
  ngOnInit() {}

  /**
   * Close notification bar
   */
  onClose() {
    this.isVisible = false;
  }
}
