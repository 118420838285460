export function loadJs({ url, id }: { url: string; id?: string }): Promise<any> {
  if (!id) {
    id = url.split('/').pop();
  }
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = url;
    script.id = id;
    script.onload = resolve;
    script.onerror = reject;
    document.body.appendChild(script);
  });
}

export function loadCss({ url, id }: { url: string; id?: string }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const cssResponse = await fetch(url);
      if (cssResponse.status !== 200) {
        reject(cssResponse);
        return;
      }
      if (!id) {
        id = url.split('/').pop();
      }
      const head = document.getElementsByTagName('HEAD')[0];
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = url;
      link.id = id;
      head.appendChild(link);
      resolve(link);
      resolve('');
    } catch (error) {
      reject(error);
    }
  });
}
