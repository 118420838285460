import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { Store } from '@ngrx/store';
import { Observable, Subscription, debounce, tap, timer } from 'rxjs';

import { IApiFilter } from '@core/interfaces/filters.interface';
import { EApiCallStateKey, EApiRequestPartKeys } from '@core/state/api-call-state/api-call-state.enum';
import { selectApiRequestPartDataSlice } from '@core/state/api-call-state/api-call-state.selectors';
import { ApiCallStateActions } from '@core/state/api-call-state/api-call-state.actions';
import { arraysEqual } from '../../utils/arrays-equal';
import { LanguagesService } from '@/src/app/core/services';

/**
 * FilterComponent
 * @date 9/2/2023 - 4:36:46 PM
 *
 * @export
 * @class FilterComponent
 * @typedef {FilterComponent}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'aup-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit, OnDestroy {
  /**
   * ApiCallStateKey
   * @date 9/2/2023 - 4:36:46 PM
   *
   * @type {EApiCallStateKey}
   */
  @Input() apiCallStateKey: EApiCallStateKey;
  /**
   * filterKey in apiCallState filters request part data
   * @date 9/2/2023 - 4:36:46 PM
   *
   * @type {string}
   */
  @Input() filterKey: string;

  /**
   * Description placeholder
   * @date 10/27/2023 - 4:37:23 PM
   *
   * @type {string}
   */
  @Input() uiKey: string = 'default';
  /**
   * Delay between formControlChange and dispatching store update
   * @date 9/2/2023 - 4:36:46 PM
   *
   * @type {number}
   */
  updateDelay: number;
  /**
   * Filter FormControl
   * @date 9/2/2023 - 4:36:46 PM
   *
   * @type {*}
   */
  formControl = new FormControl(null);
  /**
   * Filters stream
   * @date 9/5/2023 - 9:43:25 PM
   *
   * @type {Observable<IApiFilter>}
   */
  filter$: Observable<IApiFilter>;
  /**
   * Subscription
   * @date 9/2/2023 - 4:36:46 PM
   *
   * @type {Subscription}
   */
  subscription: Subscription = new Subscription();

  /**
   * Creates an instance of FilterComponent.
   * @date 9/2/2023 - 4:36:46 PM
   *
   * @constructor
   * @param {Store} store$
   */
  constructor(private store$: Store, public languagesService: LanguagesService) {}

  /**
   * Component data/subscriptions initialization
   * @date 9/2/2023 - 4:36:46 PM
   */
  ngOnInit() {
    this.initFilter();
    this.listenForFormControlChange();
  }

  /**
   * Applicable for multiple choice filter, check if option is checked
   * @date 10/27/2023 - 4:37:23 PM
   *
   * @param {*} values
   * @returns {boolean}
   */
  checkIfArrayOptionChecked(values: any): boolean {
    if (!Array.isArray(this.formControl.value)) {
      return false;
    }

    return arraysEqual(values, this.formControl.value);
  }

  /**
   * Remove filter
   * @date 9/2/2023 - 4:42:49 PM
   *
   * @param {IFilter} filter
   */
  onFilterRemove(filter: IApiFilter) {
    this.store$.dispatch(
      ApiCallStateActions.updateRequestPart({
        apiCallStateKey: this.apiCallStateKey,
        requestPartKey: EApiRequestPartKeys.FILTERS,
        data: {
          [filter.id]: { ...filter, value: filter.defaultValue },
        },
      })
    );

    this.applyChange();
  }

  /**
   * Description placeholder
   * @date 10/27/2023 - 4:37:23 PM
   *
   * @param {IApiFilter} filter
   * @returns {boolean}
   */
  checkIfActive(filter: IApiFilter) {
    return !!(filter.value && filter.value !== filter.defaultValue);
  }

  /**
   * Description placeholder
   * @date 9/5/2023 - 9:43:25 PM
   *
   * @private
   */
  private initFilter() {
    this.filter$ = this.store$
      .select(
        selectApiRequestPartDataSlice({
          apiCallStateKey: this.apiCallStateKey,
          requestPartKey: EApiRequestPartKeys.FILTERS,
          dataSliceKey: this.filterKey,
        })
      )
      .pipe(
        tap(filter => {
          if (!filter) {
            throw Error(
              `Please add configuration for filter ${this.filterKey} in apiCallState ${this.apiCallStateKey}`
            );
          }
          this.setSettings(filter);
          this.updateLocalValue(filter);
        })
      );
  }

  /**
   * Description placeholder
   * @date 9/5/2023 - 9:43:25 PM
   *
   * @private
   * @param {IApiFilter} filter
   */
  private setSettings(filter: IApiFilter) {
    this.updateDelay = filter.ui[this.uiKey].type === 'input' ? 500 : 0;
  }

  /**
   * Listen for formControl changes
   * @date 9/2/2023 - 4:36:46 PM
   *
   * @private
   */
  private listenForFormControlChange() {
    this.subscription.add(
      this.formControl.valueChanges.pipe(debounce(() => timer(this.updateDelay))).subscribe(value => {
        let finalValue = typeof value === 'string' ? value.trim() : value;
        this.onFilterChange(finalValue);
      })
    );
  }

  /**
   * Update formControl value
   * @date 9/2/2023 - 4:36:46 PM
   *
   * @private
   * @param {IApiFilter} updatedFilter
   */
  private updateLocalValue(filter: IApiFilter) {
    if (filter.value !== this.formControl.value) {
      this.formControl.patchValue(filter.value, { emitEvent: false });
    }
  }

  /**
   * Update api call state filter request part
   * @date 9/2/2023 - 4:36:46 PM
   *
   * @private
   * @param {(string | boolean | string[])} value
   */
  private onFilterChange(value: string | boolean | string[]) {
    this.store$.dispatch(
      ApiCallStateActions.resetPagination({
        apiCallStateKey: this.apiCallStateKey,
      })
    );
    this.store$.dispatch(
      ApiCallStateActions.updateRequestPartDataSlice({
        apiCallStateKey: this.apiCallStateKey,
        requestPartKey: EApiRequestPartKeys.FILTERS,
        dataSliceKey: this.filterKey,
        data: { value: value === '' ? null : value },
      })
    );
    this.applyChange();
  }

  /**
   * Trigger api call state load
   * @date 10/27/2023 - 4:37:23 PM
   *
   * @private
   */
  private applyChange() {
    this.store$.dispatch(
      ApiCallStateActions.updateRequestPart({
        apiCallStateKey: this.apiCallStateKey,
        requestPartKey: EApiRequestPartKeys.PAGINATION,
        data: { page: 0 },
      })
    );
    this.store$.dispatch(ApiCallStateActions.load({ apiCallStateKey: this.apiCallStateKey }));
  }

  /**
   * Destroy component
   * @date 9/2/2023 - 4:36:46 PM
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
