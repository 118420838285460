import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { Pec } from '@models';
import { IApiResponse } from '../../interfaces';

export const PecActions = createActionGroup({
  source: 'Pec',
  events: {
    load: emptyProps(),
    loaded: props<{ response: IApiResponse<Pec[]> }>(),
    loadError: props<{ error: any }>(),
  },
});
