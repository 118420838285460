import { FelPermission } from '@models';
import { ApiService } from './api.service';
import { Injectable, Injector } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class FelPermissionsService extends ApiService {
  constructor(override injector: Injector) {
    super('permissions/fel', FelPermission, injector);
  }
}
