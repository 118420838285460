import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { UsersService } from '@services';
import { SessionActions } from '@core/state/session/session.actions';
import { AppState } from '@core/state';
import { Store } from '@ngrx/store';
import { EUserStatus } from '../enums';
import { Observable, map, of } from 'rxjs';
import { IApiResponse } from '../interfaces';
import { User } from '../models';

/**
 * AuthGuard
 *
 * @export
 * @class AuthGuard
 * @typedef {AuthGuard}
 */
@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  /**
   * Creates an instance of AuthGuard.
   *
   * @constructor
   * @param {Router} router
   * @param {OAuthService} oauthService
   */
  constructor(
    private readonly router: Router,
    private readonly oauthService: OAuthService,
    private readonly userService: UsersService,
    private store: Store<AppState>
  ) {}

  /**
   * Can activate route guard
   *
   * @returns {boolean}
   */
  canActivate(): Observable<boolean> {
    if (this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken()) {
      return this.userService.getMe().pipe(
        map((response: IApiResponse<User>): boolean => {
          this.store.dispatch(SessionActions.setUser({ user: response.data }));
          if (response.data.status === EUserStatus.SOSPESO) {
            this.router.navigate(['/user-suspended']);
            return false;
          } else {
            return true;
          }
        })
      );
    } else {
      this.router.navigate(['/sign-in']);
      return of(false);
    }
  }
}
