import { Injectable } from '@angular/core';
import { DateTimeProvider } from 'angular-oauth2-oidc';

@Injectable()
export class CustomDateTimeProvider extends DateTimeProvider {
  now(): number {
    const timeDifferenceInSeconds = localStorage.getItem('time_difference_in_seconds') || '0';
    return Date.now() + parseInt(timeDifferenceInSeconds, 10) * 1000;
  }

  new(): Date {
    const timeDifferenceInSeconds = localStorage.getItem('time_difference_in_seconds') || '0';
    return new Date(Date.now() + parseInt(timeDifferenceInSeconds, 10) * 1000);
  }
}
