import { filterData } from 'filter-data';
import sortArray from 'sort-array';
import { IApiFiltersList, IDataFilterSearchCondition } from '../../interfaces/filters.interface';
import { EApiRequestPartKeys } from '../api-call-state/api-call-state.enum';
import { IApiCallState } from '../api-call-state/api-call-state.interface';
import { IApiPagination, IApiSorting } from '../../interfaces';

export function applyFilters<T>(data: T, apiCallState: IApiCallState): T {
  const filtersRequestPart = getApiRequestPart(apiCallState, EApiRequestPartKeys.FILTERS);
  if (!filtersRequestPart || !Array.isArray(data)) {
    return data;
  }

  const activeFilters = getActiveFilters(filtersRequestPart.data);

  if (!activeFilters?.length) {
    return data;
  }

  const searchCondition: IDataFilterSearchCondition[] = activeFilters.map(filter => {
    const { property, value, comparator } = filter;
    return {
      key: property,
      type: comparator,
      value,
    };
  });

  return filterData<T>(data, searchCondition) as T;
}

export function applySort<T>(data: T, apiCallState: IApiCallState): T {
  if (!Array.isArray(data)) {
    return data;
  }

  const sortApiPart = getApiRequestPart(apiCallState, EApiRequestPartKeys.SORTING);
  if (!sortApiPart.data) {
    return data;
  }

  const { property, direction }: IApiSorting = sortApiPart.data;

  if (!property || !direction) {
    return data;
  }

  return sortArray([...data], { by: property, order: direction });
}

export function applyPagination<T>(data: T, apiCallState: IApiCallState): T {
  if (!Array.isArray(data)) {
    return data;
  }

  const paginationRequestPart = getApiRequestPart(apiCallState, EApiRequestPartKeys.PAGINATION);

  if (!paginationRequestPart?.data?.size) {
    return data;
  }

  const { page, size }: IApiPagination = paginationRequestPart.data;
  const firstItemIndex = page * size || 0;
  const lastItemIndex = firstItemIndex + size;
  return data.slice(firstItemIndex, lastItemIndex) as T;
}

function getApiRequestPart(apiCallState: IApiCallState, requestPartKey: EApiRequestPartKeys) {
  return apiCallState.requestParts.find(apiRequestPart => apiRequestPart.key === requestPartKey);
}

function getActiveFilters(filtersList: IApiFiltersList) {
  return Object.values(filtersList).filter(filter => filter.value !== null);
}
