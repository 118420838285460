import { User } from '@models';

export interface IBreakpoints {
  isXSmall: boolean;
  isSmall: boolean;
  isMedium: boolean;
  isLarge: boolean;
  isXLarge: boolean;
  isHandset: boolean;
  isTablet: boolean;
  isWeb: boolean;
  isHandsetPortrait: boolean;
  isTabletPortrait: boolean;
  isWebPortrait: boolean;
  isHandsetLandscape: boolean;
  isTabletLandscape: boolean;
  isWebLandscape: boolean;
  // Computed value
  isHandsetLayout?: boolean;
}
export interface ISessionState {
  user: User;
  breakpoints: IBreakpoints;
}

export const initialState: ISessionState = {
  user: null,
  breakpoints: {} as IBreakpoints,
};
