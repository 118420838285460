import justClone from 'just-clone';

export abstract class SuperModel {
  id: string;
  createdAt?: string | Date;
  modifiedAt?: string | Date;

  constructor(object: any = {}) {
    Object.assign(this, object);
    if (!object) {
      return;
    }
  }

  abstract readonly serialised;

  get clone() {
    return justClone(this.serialised);
  }
}
