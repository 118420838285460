import { E2FAStatus, EUserStatus } from '../enums';
import { SuperModel } from './super-model.model';

export class User extends SuperModel {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  status: EUserStatus;
  passwordExpirationTs: string;
  expirationAlertTs: string;
  showExpirationAlert: boolean;
  maxPecEnabled: number;
  status2fa: E2FAStatus;
  pecDelegationsNumber: number;
  felDelegationsNumber: number;
  owner: {
    name: string;
    surname: string;
    businessName: string;
    email: string;
    taxCode: string;
    vatNumber: string;
    state: string;
  };

  constructor(data: any = {}) {
    super(data);

    this.createdAt = new Date(data?.userCreatedAt);
  }

  get serialised() {
    return {};
  }
}
