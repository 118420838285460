export function compareDates(date1: Date | string, date2: Date | string): number {
  // Convert both dates to milliseconds
  const d1 = new Date(date1).getTime();
  const d2 = new Date(date2).getTime();

  // Compare the two dates and return the result
  if (d1 < d2) {
    return -1; // date1 is earlier than date2
  } else if (d1 > d2) {
    return 1; // date1 is later than date2
  } else {
    return 0; // both dates are equal
  }
}
