export enum EFelStatus {
  DELEGATED = 'DELEGATED', // delegato,
  WAITING = 'WAITING', // in attesa /
  TO_ACCEPT = 'TO_ACCEPT', // da accettare
  SUSPENDED = 'SUSPENDED', // sospeso
  REFUSED = 'REFUSED', // rifiutato
  REVOKED = 'REVOKED', // revocato
}

export enum EFelAction {
  MANAGE = 'MANAGE',
  REVOKE = 'REVOKE',
  MODIFY = 'MODIFY',
  ACCESS = 'ACCESS',
  NEW = 'NEW',
  HIDE = 'HIDE',
}

export enum EFelDelegationModalContent {
  FIRST_STEP = 'FIRST_STEP',
  SECOND_STEP = 'SECOND_STEP',
  USER_INVITED = 'USER_INVITED',
  REQUEST_SENT = 'REQUEST_SENT',
}

export enum EIdentityType {
  PARTITA_IVA = 'partitaIva',
  CODICE_FISCALE = 'codiceFiscale',
}

export enum EFelComponentAction {
  INVITE_USER = 'INVITE_USER',
  GO_TO_SECOND_STEP = 'GO_TO_SECOND_STEP',
  CANCEL = 'CANCEL',
  SUBMIT_DATA = 'SUBMIT_DATA',
  REFUSE = 'REFUSE',
}
