import { IApiRequest, IApiSorting } from '../../interfaces';
import { IApiFilter, IApiFiltersList } from '../../interfaces/filters.interface';
import { EApiRequestPartKeys } from '../api-call-state/api-call-state.enum';
import { IApiCallState } from '../api-call-state/api-call-state.interface';

export function mapApiCallStateToRequestParams(apiCallState: IApiCallState): IApiRequest {
  let apiRequest: IApiRequest = {};
  apiRequest = mapPagination(apiCallState, apiRequest);
  apiRequest = mapSorting(apiCallState, apiRequest);
  apiRequest = mapFilters(apiCallState, apiRequest);
  return apiRequest;
}

function mapPagination(apiCallState: IApiCallState, apiRequest: IApiRequest) {
  const paginationPart = getRequestPart(apiCallState, EApiRequestPartKeys.PAGINATION);
  if (paginationPart) {
    Object.assign(apiRequest, { page: paginationPart.data.page, size: paginationPart.data.size });
  }

  return apiRequest;
}

function mapSorting(apiCallState: IApiCallState, apiRequest: IApiRequest) {
  const sortPart = getRequestPart(apiCallState, EApiRequestPartKeys.SORTING);
  const { property, direction } = sortPart.data as IApiSorting;

  if (property && direction) {
    Object.assign(apiRequest, { sorting: [sortPart.data] });
  }
  return apiRequest;
}

function mapFilters(apiCallState: IApiCallState, apiRequest: IApiRequest) {
  const filtersPart = getRequestPart(apiCallState, EApiRequestPartKeys.FILTERS);
  const activeFilers: IApiFilter[] = Object.values(filtersPart.data as IApiFiltersList).filter(
    filterItem => filterItem.value !== null && !(Array.isArray(filterItem.value) && !filterItem.value.length)
  );

  if (activeFilers?.length) {
    Object.assign(apiRequest, { filters: activeFilers });
  }
  return apiRequest;
}

function getRequestPart(apiCallState: IApiCallState, requestPartKey: EApiRequestPartKeys) {
  return apiCallState.requestParts.find(requestPart => requestPart.key === requestPartKey);
}
