<div *ngIf="data"
  class="flex flex-col h-full">
  <div class="aup-background-gray px-5 flex items-center justify-between">
    <h2 class="!p-0 w-2/3 md:w-auto"
      mat-dialog-title>{{data.title | transloco}}</h2>
    <button type="button"
      (click)="onClose({status: EDialogCloseStatus.CANCEL})"
      mat-icon-button>
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>
  <div class="p-6">
    <p [innerHTML]="data.message | transloco"></p>
  </div>
  <div class="flex !items-start !p-6"
    mat-dialog-actions>
    <button mat-flat-button
      color="primary"
      (click)="onClose({status: EDialogCloseStatus.OK })">
      {{data.okLabel || 'COMMON.BUTTON.OK' | transloco}}
    </button>
    <button mat-flat-button
      color="secondary"
      (click)="onClose({status: EDialogCloseStatus.CANCEL })">
      {{data.cancelLabel || 'COMMON.BUTTON.CANCEL' | transloco}}
    </button>
  </div>
</div>