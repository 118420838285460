import { ENewsAction } from '@/src/app/core/enums';
import { INewsAction } from '@/src/app/core/interfaces';
import { News } from '@/src/app/core/models';
import { Component, EventEmitter, Input, Output, QueryList, TemplateRef, ViewChildren } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
@Component({
  selector: 'aup-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss'],
})
export class NewsListComponent {
  /**
   * Actions menus query list
   * @date 11/9/2023 - 8:37:01 PM
   *
   * @type {QueryList<TemplateRef<any>>}
   */
  @ViewChildren('actionsMenu') actionsMenus: QueryList<TemplateRef<any>>;

  @Input() news: News[];
  @Input() selectedNews: News;
  @Input() isMobile: boolean;
  @Output() select = new EventEmitter();
  @Output() action: EventEmitter<INewsAction> = new EventEmitter<INewsAction>();

  constructor(private bottomSheet: MatBottomSheet) {}

  onNewsClick(news: News) {
    this.select.emit(news);
  }

  /**
   * Open bottom sheet
   * @date 11/9/2023 - 8:37:01 PM
   *
   * @param {number} index
   */
  openMenu(index: number) {
    this.bottomSheet.open(this.actionsMenus.get(index));
  }

  onChangeRead(news: News, markAsRead: boolean) {
    const newsAction = markAsRead ? ENewsAction.MARK_AS_READ : ENewsAction.MARK_AS_UNREAD;
    this.action.emit({ news, action: newsAction });
    this.bottomSheet.dismiss();
  }
}
