import { Injectable, Injector } from '@angular/core';
import { ApiService } from './api.service';
import { News } from '@models';
import { Observable, delay, map, of } from 'rxjs';
import { IApiRequest, IApiResponse } from '../../interfaces';
import { tempNewsResponseMock } from './news-mock';

@Injectable({
  providedIn: 'root',
})
export class NewsService extends ApiService {
  constructor(injector: Injector) {
    super('news', News, injector);
  }

  override patch<T>(requestParams?: IApiRequest): Observable<IApiResponse<T>> {
    return of({
      data: requestParams?.body,
    } as IApiResponse<T>).pipe(delay(100));
  }

  override get<News>(requestParams?: IApiRequest): Observable<IApiResponse<News[]>> {
    return super.get({
      queryParams: {
        distributionChannelId: 1,
        languageCode: this.translocoService.getActiveLang()?.toUpperCase(),
      },
      ...requestParams,
    });

    // NOTE: this is code used to test news components without api,
    // will be removed at the end of news module in future PR
    // return of(tempNewsResponseMock).pipe(
    //   map((envelope: IApiResponse<any>) => {
    //     const response = {
    //       ...envelope,
    //       data: (envelope.data as News[]).map(e => new this.entityClass(e)) as News[],
    //     };

    //     //response.data = response.data.slice(0, 3);
    //     return response;
    //   })
    // );
  }

  getSuperNews() {
    return this.request<News[]>({
      method: 'GET',
      path: '/supernews',
      queryParams: {
        distributionChannelId: 1,
        languageCode: this.translocoService.getActiveLang()?.toUpperCase(),
      },
    });
  }
}
