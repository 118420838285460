/**
 * News category enum
 *
 * @export
 * @enum {string}
 */
export enum ENewsCategory {
  PEC = 'PEC',
  FEL = 'FEL',
}

/**
 * News action enum
 *
 * @export
 * @enum {string}
 */
export enum ENewsAction {
  MARK_AS_UNREAD = 'MARK_AS_UNREAD',
  MARK_AS_READ = 'MARK_AS_READ',
}
