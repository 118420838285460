import { ENewsCategory } from '../enums';
import { SuperModel } from './super-model.model';

export class News extends SuperModel {
  title: string;
  category: string;
  serviceType: ENewsCategory;
  html: string;
  date: Date;
  isReaded: boolean;

  constructor(data = {}) {
    super(data);
  }

  get serialised() {
    return {
      title: this.title,
      category: this.category,
      serviceType: this.serviceType,
      html: this.html,
      date: this.date,
      isReaded: this.isReaded,
    };
  }
}
