import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AppConfigService } from '@/src/app/core/services';
import { loadJs } from '@/src/app/core/utils';

@Component({
  selector: 'aup-recaptcha',
  templateUrl: './recaptcha.component.html',
  styleUrls: ['./recaptcha.component.scss'],
})
export class RecaptchaComponent implements OnInit {
  @Output() onReCaptchaResolved: EventEmitter<string> = new EventEmitter<string>();

  public siteKey;

  constructor(public appConfigService: AppConfigService) {}

  ngOnInit(): void {
    this.siteKey = this.appConfigService.getConfig().reCaptchaSiteKey;
  }
}
