import { Injectable, Injector } from '@angular/core';
import { ApiService } from './api.service';
import { User } from '@models';
import { IApiResponse } from '../../interfaces';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UsersService extends ApiService {
  constructor(injector: Injector) {
    super('user', User, injector);
  }

  getMe(): Observable<IApiResponse<User>> {
    return this.getById<User>({
      path: '/datainfo',
      method: 'GET',
    });
  }
}
