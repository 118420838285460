import { marker } from '@ngneat/transloco-keys-manager/marker';
import { EApiCallStateKey, EApiRequestPartKeys, EApiRequestPartsNames } from '../api-call-state.enum';
import { IApiCallState } from '../api-call-state.interface';
import { SearchType } from 'filter-data';
import { IApiFiltersList } from '../../../interfaces/filters.interface';

export const newsApiCallState: IApiCallState = {
  key: EApiCallStateKey.NEWS,
  requestParts: [
    {
      key: EApiRequestPartKeys.PAGINATION,
      partName: EApiRequestPartsNames.PAGINATION,
      data: {
        page: 0,
        size: 10,
      },
    },
    {
      key: EApiRequestPartKeys.SORTING,
      partName: EApiRequestPartsNames.SORTING,
      data: {},
    },
    {
      key: EApiRequestPartKeys.FILTERS,
      partName: EApiRequestPartsNames.FILTER,
      data: {
        date: {
          id: 'date',
          property: 'date',
          value: null,
          defaultValue: null,
          comparator: SearchType.EQ,
          ui: {
            default: {
              type: 'date',
            },
          },
          chips: {
            label: marker('NEWS.FILTERS.CHIP_LABEL.DATE'),
            show: true,
            type: 'date',
          },
        },
        category: {
          id: 'category',
          property: 'category',
          value: null,
          defaultValue: null,
          comparator: SearchType.EQ,
          ui: {
            default: {
              type: 'dropdown',
              options: [],
            },
          },
          chips: {
            show: true,
            label: marker('NEWS.FILTERS.CHIP_LABEL.CATEGORY'),
            type: 'options',
          },
        },
        title: {
          id: 'title',
          property: 'title',
          value: null,
          defaultValue: null,
          comparator: SearchType.LK,
          ui: {
            default: {
              type: 'input',
            },
          },
          chips: {
            label: marker('NEWS.FILTERS.CHIP_LABEL.TITLE'),
            show: true,
            type: 'value',
          },
        },
        isReaded: {
          id: 'isReaded',
          property: 'isReaded',
          value: null,
          defaultValue: null,
          comparator: SearchType.EQ,
          ui: {
            default: {
              type: 'buttons',
              options: [
                {
                  value: null,
                  label: marker('NEWS.FILTER_OPTIONS.SHOW_ALL'),
                },
                {
                  value: false,
                  label: marker('NEWS.FILTER_OPTIONS.SHOW_UNREAD'),
                },
                {
                  value: true,
                  label: marker('NEWS.FILTER_OPTIONS.SHOW_READ'),
                },
              ],
            },
          },
          chips: {
            show: false,
            label: '',
          },
        },
      } as IApiFiltersList,
    },
  ],
};
